
import { index } from "./components/index";
import { group } from "./components/group";
import { business } from "./components/business";
import { recruit } from "./components/recruit";

import simpleParallax from 'simple-parallax-js';
import ScrollHint from 'scroll-hint'
import { throttle } from 'throttle-debounce'
new ScrollHint('.js-scrollable', {
	scrollHintIconAppendClass: 'scroll-hint-icon-white', // white-icon will appear
	applyToParents: true,
	i18n: {
		scrollable: 'スクロールできます'
	}
});
setTimeout(() => {
	const y = window.scrollY
	if (y > 0) {
		// setTimeout(() => window.scrollTo(0, 0), 200)
	}
}, 0)


const images = document.querySelectorAll('.parallax');
new simpleParallax(images, {
	scale: 1.2,
});


index();
group();
business();
recruit();

document.addEventListener('DOMContentLoaded', () => {
	window.addEventListener(
		'scroll',
		throttle(100, () => {
			if (document.querySelector('.header')) {
				getHeader();
			}
			getSplittext();
			getFadeup();
			getCount();
		}),
		false
	);
});





const header = document.querySelector('.header');
const pHeader = document.querySelector('.p-header');
const getHeader = () => {
	const position = Math.floor(window.innerHeight * 0);
	const scrollPosition = window.scrollY;

	let clientRect = header.getBoundingClientRect();
	let offsetTop = Math.floor(clientRect.top);
	// let offsetBottom = Math.floor(clientRect.bottom);
	let offsetBottom = window.innerHeight / 2;
	// console.log(position, offsetBottom, scrollPosition)
	if (offsetBottom < scrollPosition) {
		header.classList.add('_active');
	} else {
		header.classList.remove('_active');
	}
}


const drawernavBtn = document.querySelector('.drawernav_btn');
const drawernav = document.querySelector('.drawernav');
const drawernavBg = document.querySelector('.drawernav_bg');

if (drawernavBtn) {
	drawernavBtn.addEventListener("click", function (e) {
		e.preventDefault();
		document.querySelector('.spnav').classList.toggle('_open');
		drawernavBg.classList.toggle('_active');
		setTimeout(() => {
			drawernav.classList.toggle('_active');
		}, 400);
		setTimeout(() => {
			drawernavBg.classList.toggle('_active');
		}, 500);
	})
}




/*
@
*/
document.addEventListener('DOMContentLoaded', () => {
	const elm = document.querySelectorAll('.split_text');
	for (let t = 0; t < elm.length; t++) {
		/*
		const cut = elm[t].innerHTML.trim().split("");
		elm[t].innerHTML = cut.reduce((i, j) => {
			j = j.replace(/\s+/, '&nbsp;');//replace：置換
			return `${i}<span>${j}</span>`;

		}, "");
		*/

		const str = elm[t].innerHTML;
		// const arr = parts.split(/<br.*?>/); // 正規表現を使って、<br>タグに付いている属性も一緒に除去します。
		// const str = "Hello<br class='sponly'>, world!";
		let output = "";
		let isInsideBr = false;
		for (let i = 0; i < str.length; i++) {
			if (str[i] === "<" || str.slice(i, i + 4) === "<br") {
				isInsideBr = true;
				// console.log(str.slice(i, i + 19));
				if (str.slice(i, i + 19) == '<br class="sponly">') {
					output += '<br class="sponly">';
					// console.log("a");
					i += 18;
				} else {
					output += "<br>";
					i += 3;
				}
			} else {
				output += "<span>" + str[i] + "</span>";
			}
		}
		// console.log(output);
		elm[t].innerHTML = output;

	}
});
const getSplittext = () => {
	const elm = document.querySelectorAll('.split_text');

	for (let t = 0; t < elm.length; t++) {
		let clientRect = elm[t].getBoundingClientRect();
		let y = clientRect.top;
		// console.log(window.innerHeight);
		// let num = getDeviceType() === 'lg' ? 750 : 600;


		// let num = 600;
		let num = window.innerHeight / 2;

		if (y <= num) {
			if (!elm[t].classList.contains('_animation')) {
				elm[t].classList.add('_animation');
			}
		} else {
			// elm[t].classList.remove('_animation');
		}
		/*
		*/
	}
}


/*
@
*/
const getFadeup = () => {
	const elm = document.querySelectorAll('.fadeup_animation');
	for (let t = 0; t < elm.length; t++) {
		let clientRect = elm[t].getBoundingClientRect();
		let y = clientRect.top;
		let num = window.innerHeight / 1.25;

		if (y <= num) {
			if (!elm[t].classList.contains('_animation')) {
				elm[t].classList.add('_animation');
			}
		} else {
			elm[t].classList.remove('_animation');
		}
	}

	const elmFade = document.querySelectorAll('.fade_animation');
	for (let t = 0; t < elmFade.length; t++) {
		let clientRect = elmFade[t].getBoundingClientRect();
		let y = clientRect.top;
		let num = window.innerHeight / 1.25;

		if (y <= num) {
			if (!elmFade[t].classList.contains('_animation')) {
				elmFade[t].classList.add('_animation');
			}
		} else {
			elmFade[t].classList.remove('_animation');
		}
	}

}





///


document.addEventListener("DOMContentLoaded", function () {
	var lazyloadImages;

	if ("IntersectionObserver" in window) {
		lazyloadImages = document.querySelectorAll(".bg-lazy");
		var imageObserver = new IntersectionObserver(function (entries, observer) {
			entries.forEach(function (entry) {
				if (entry.isIntersecting) {
					var image = entry.target;
					image.classList.remove("bg-lazy");
					imageObserver.unobserve(image);
				}
			});
		});

		lazyloadImages.forEach(function (image) {
			imageObserver.observe(image);
		});
	} else {
		var lazyloadThrottleTimeout;
		lazyloadImages = document.querySelectorAll(".bg-lazy");

		function lazyload() {
			if (lazyloadThrottleTimeout) {
				clearTimeout(lazyloadThrottleTimeout);
			}

			lazyloadThrottleTimeout = setTimeout(function () {
				var scrollTop = window.pageYOffset;
				lazyloadImages.forEach(function (img) {
					if (img.offsetTop < (window.innerHeight + scrollTop)) {
						img.src = img.dataset.src;
						img.classList.remove('bg-lazy');
					}
				});
				if (lazyloadImages.length == 0) {
					document.removeEventListener("scroll", lazyload);
					window.removeEventListener("resize", lazyload);
					window.removeEventListener("orientationChange", lazyload);
				}
			}, 20);
		}

		document.addEventListener("scroll", lazyload);
		window.addEventListener("resize", lazyload);
		window.addEventListener("orientationChange", lazyload);
	}
})


//


const smoothScrollTrigger = document.querySelectorAll('a[href^="#"]');
for (let i = 0; i < smoothScrollTrigger.length; i++) {
	smoothScrollTrigger[i].addEventListener('click', (e) => {
		e.preventDefault();
		let href = smoothScrollTrigger[i].getAttribute('href');
		let targetElement = document.getElementById(href.replace('#', ''));

		if (targetElement) {
			const rect = targetElement.getBoundingClientRect().top;
			const offset = window.pageYOffset;
			let gap = 0;
			/*
			if (isSmartPhone()) {
				gap = 60;
			} else {
				gap = 140;
			}
			*/
			const target = rect + offset - gap;
			window.scrollTo({
				top: target,
				behavior: 'smooth',
			});
		}
	});
}


//


const ms = 800;
const formatter = new Intl.NumberFormat();

const getCount = () => {

	const position = Math.floor(window.innerHeight * 1);


	Array.prototype.forEach.call(document.querySelectorAll('.count'), function (el, i) {
		let clientRect = el.getBoundingClientRect();
		let offsetTop = Math.floor(clientRect.top);
		if (offsetTop < position) {
			if (!el.classList.contains('_active')) {
				el.classList.add('_active');
				const delay = (el.hasAttribute("data-delay")) ?
					el.getAttribute("data-delay") : 0;
				const comma = el.textContent.indexOf(',') > -1;
				const decimal = el.textContent.indexOf('.') > -1;

				const max = parseFloat(el.textContent.replace(/,/g, ''), 10);
				console.log(max);
				el.textContent = '0';
				setTimeout(() => {
					const start = new Date().getTime();
					let id = setInterval(function () {
						const currentTime = Math.min(new Date().getTime() - start, ms);
						const num = decimal ? (max * currentTime / ms).toFixed(1) : Math.floor(max * currentTime / ms);
						el.textContent = comma ? formatter.format(num) : num;
						if (currentTime >= ms) clearInterval(id);
					}, 20);
				}, delay * 200);
			}
		}

	})
}
