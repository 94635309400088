// const moment = require('moment');
// import { gsap } from "gsap";
// import { ScrollTrigger } from 'gsap/ScrollTrigger'
// import { Navigation, Pagination, Autoplay, EffectFade, Swiper } from 'swiper'
// Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);
// gsap.registerPlugin(ScrollTrigger);
import Splide from '@splidejs/splide';

export function index() {

	if (document.querySelector('.p-home_mv .splide')) {

		document.addEventListener('DOMContentLoaded', () => {
			setTimeout(() => {
				document.querySelector(".p-home_mv h2").classList.add('_animation');
			}, 1000);


			const elm = document.querySelectorAll('.text');
			for (let t = 0; t < elm.length; t++) {
				const cut = elm[t].innerHTML.trim().split("");
				elm[t].innerHTML = cut.reduce((i, j) => {
					j = j.replace(/\s+/, '&nbsp;');//replace：置換
					return `${i}<span>${j}</span>`;

				}, "");
			}


			const texts = document.querySelectorAll(".p-home_mv_copy li");
			const slides = document.querySelectorAll(".p-home_mv .splide__slide");


			const splide = new Splide('.p-home_mv .splide', {
				pauseOnHover: false,
				speed: 2000,
				interval: 6000,
				type: 'fade',
				rewind: true,
				autoplay: true,
				arrows: false,
				easing: 'ease'
			}).mount();
			if (splide.state.is(Splide.STATES.IDLE)) {
				// console.log("IDLE")
				texts[0].classList.add('_active');
				slides[0].classList.add('_active');
				setTimeout(() => {
					texts[0].classList.add('_animation');
				}, 300);
			}
			splide.on('moved', function (newIndex, prevIndex, destIndex) {
				// console.log("moved")
				texts[prevIndex].classList.remove('_active');
				texts[prevIndex].classList.remove('_animation');
				texts[newIndex].classList.add('_active');
				slides[newIndex].classList.add('_active');
				setTimeout(() => {
					slides[prevIndex].classList.remove('_active');
					texts[newIndex].classList.add('_animation');
				}, 300);
				// console.log(newIndex, prevIndex, destIndex);
			});

			//
			setTimeout(() => {

				const photoLeft = document.querySelector('.photo_left');
				const photoLeftPosition = photoLeft.getBoundingClientRect().top - window.innerHeight * 0.3;

				const photoRight = document.querySelector('.photo_right');
				const photoRightPosition = photoRight.getBoundingClientRect().top - window.innerHeight * 0.2;

				function moveBox() {
					const scrollPosition = window.scrollY;

					if (scrollPosition >= photoLeftPosition) {
						const distance = (scrollPosition - photoLeftPosition) * 0.3;
						photoLeft.style.transform = `translateY(-${distance}px)`;
					}

					if (scrollPosition >= photoRightPosition) {
						const distance = (scrollPosition - photoRightPosition) * 0.3;
						photoRight.style.transform = `translateY(-${distance}px)`;
					}
				}

				window.addEventListener('scroll', moveBox);
			}, 500);


			const number = document.querySelectorAll('.number_animation');
			number.forEach(element => {
				const elementPosition = element.getBoundingClientRect().top - window.innerHeight * 0.3;


				function moveBox() {
					const scrollPosition = window.scrollY;

					if (scrollPosition >= elementPosition) {
						const distance = (scrollPosition - elementPosition) * 0.15;
						element.style.transform = `translateY(-${distance}px)`;
					}

				}
				window.addEventListener('scroll', moveBox);
			})
			//

			// .recruit要素を取得する
			const recruitElement = document.querySelector('.p-home_recruit');
			const options = {
				root: null, // 今回はビューポートをルート要素とする
				rootMargin: "-50% 0px", // ビューポートの中心を判定基準にする
				threshold: 0 // 閾値は0
			};
			// IntersectionObserverを作成する
			const observer = new IntersectionObserver(entries => {
				// // console.log(entries);
				if (entries[0].isIntersecting) {
					recruitElement.classList.add('_active');
				} else {
					recruitElement.classList.remove('_active');
				}
			}, options);

			// .recruit要素を監視する
			observer.observe(recruitElement);
		});
	}

}
