
export function group() {

	if (document.querySelector('.p-group')) {

		function boduHeight() {
			let maxHeight = 0;

			for (let i = 0; i < bodyItem.length; i++) {
				maxHeight = Math.max(maxHeight, bodyItem[i].offsetHeight);
			}
			// console.log(maxHeight);
			document.querySelector('.group_body').style.height = `${maxHeight}px`;
		}

		const bodyItem = document.querySelectorAll('.group_body_item');
		const listItem = document.querySelectorAll('.group_list .item');
		let index = 0;
		let intervalId = null;

		function switchElements() {
			listItem.forEach(element => {
				element.classList.remove('_active');
			});
			bodyItem.forEach(element => {
				element.classList.remove('_active');
			});

			listItem[index].classList.add('_active');
			bodyItem[index].classList.add('_active');

			index = (index + 1) % listItem.length;
		}

		document.querySelector('.group_list').addEventListener('mouseover', function () {
			clearInterval(intervalId);
			// console.log('stop');
		});

		document.querySelector('.group_list').addEventListener('mouseout', function () {
			intervalId = setInterval(switchElements, 5000);
		});

		intervalId = setInterval(switchElements, 5000);


		listItem.forEach(element => {
			element.addEventListener('click', function (event) {
				const parentElement = element.parentNode;
				const clickedIndex = Array.prototype.indexOf.call(parentElement.children, element);
				// console.log(clickedIndex);
				index = clickedIndex;
				listItem.forEach(element => {
					element.classList.remove('_active');
				});
				bodyItem.forEach(element => {
					element.classList.remove('_active');
				});

				listItem[index].classList.add('_active');
				bodyItem[index].classList.add('_active');
			});
		});

		window.addEventListener("resize", function () {
			boduHeight();
		})
		boduHeight();
	}
}
